header.AppHeader{
  background-color: rgba(15,12,36,0.16);
  z-index: 10;
  position: fixed;
  width:100%;
  height: 65px;
  img{
    height: 36px;
    margin: 13px 20px;
  }

}

header.solid{
  background-color: rgba(15,12,36,1);
  transition: background-color 500ms ease-out;
}
