// Light
@font-face {
font-family: "MarkWeb";
font-display: swap;
src: url("../fonts/MWP-SB-Light.33c1ed9c.woff2") format("woff2");
font-weight: 200;
}

// Regular
@font-face {
  font-family: "MarkWeb";
  font-display: swap;
  src: url("../fonts/MWP-SB-Reg.beef3faf.woff2") format("woff2");
  font-weight: 400;
}

// Medium
@font-face {
  font-family: "MarkWeb";
  font-display: swap;
  src: url("../fonts/MWP-SB-Med.405e0f3c.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}

h2{
  font-family: "MarkWeb",sans-serif;
  font-weight: 700;
  font-size: 24px
}

h3{
  font-family: "MarkWeb",sans-serif;
  font-weight: 400;
  color: #50E3C2;
  font-size: 16px;
  text-transform: uppercase;
}

p{
  font-family: "MarkWeb",sans-serif;
  font-weight: 700;
  font-size: 16px;
}
