@import 'typography.scss';

.exampleContainer{
  padding-top: 100vh;
}

.examples{
  margin: 0px 30px 0px 30px;
  text-align: center;

  h2{
    font-family: "MarkWeb",sans-serif;
    font-weight: 700;
    color: #1A2343;
    font-size: 24px;
  }

  .exampleItem{
    padding: 20px 0px 100px 0px;
    border-bottom: 1px solid #CCCCCC;
    &:last-child{
      border:none;
    }
    p{
      font-weight: 400;
      font-size: 16px;
    }
  }
}
