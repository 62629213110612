@import 'colours';
@import 'easings';

.CardActivation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -200px 0 0 -140px;
    width: 280px;
    height: 400px;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 20px 20px rgba(black, 0.2);
    text-align: center;
    box-sizing: border-box;
    pointer-events: none;

    will-change: transform, opacity;
    transform: translateY(200%);
    transition: transform 0.700s $ease-out-quart, opacity 0.250s linear;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    &--full {
      // Full background
      width: 747px;
      height: 100%;
      margin-left: -233px;
    }

    &--small {
      // Behind card only
      width: 462px;
      height: 270px;
      margin-left: -96px;
    }
  }

  &__content {
    position: relative;
    box-sizing: border-box;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 30px 10px 0px 10px;
    background: linear-gradient(180deg, rgba(white, 0) 50%, rgba(white, 1) 80%);
  }

  &__card {
    width: 110px;
    height: 173px;
    background-image: url('../assets/starling_card.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto 20px auto;

    will-change: transform;
    transform: translateY(250%);
    transition: transform 0.700s $ease-out-quart;
    transition-delay: 0.300s;
  }

  &__message {
    margin-bottom: 20px;

    p {
      margin: 0;
      color: $dark-text;
      line-height: 1.2;
      margin-top: -5px;

      opacity: 0;
      // will-change: transform, opacity;
      transform: translateY(100%);
      transition: opacity 0.700s linear, transform 0.700s $ease-out-quad;
      transition-delay: 1.400s;
    }
  }

  &__icon {
    width: 50px;

    opacity: 0;
    transform: scale(3);
    // will-change: transform, opacity;
    transition: opacity 0.500s linear, transform 0.700s $ease-out-quart;
    transition-delay: 0.800s;
  }

  &__button {
    opacity: 0;
    // will-change: transform, opacity;
    transform: translateY(100%);
    transition: opacity 0.900s linear, transform 0.700s $ease-out-quad;
    transition-delay: 1.600s;
  }

  &--entry &__modal {
    transform: translateY(0);
    pointer-events: all;
  }

  &--entry &__card {
    transform: translateY(0);
  }

  &--entry &__icon {
    opacity: 1;
    transform: scale(1);
  }

  &--entry &__message p {
    opacity: 1;
    transform: translateY(0);
  }

  &--entry &__button {
    opacity: 1;
    transform: translateY(0);
  }

  &--exit &__modal {
    opacity: 0;
    transform: translateY(-5%);
    transition: transform 0.300s ease-out, opacity 0.200s linear;
    pointer-events: none;
  }

}