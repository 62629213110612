.footer {
  background-color: #F7F7F7;
  text-align: center;
  font-size: 14px;
  color: #161918;
  padding: 20px 30px 30px 30px;
  clear: both;

  .footer__copyright {
    margin: 20px auto;
    max-width: 450px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Avenir', sans-serif!important;
  }

  &__logos {
    width: 100%;
    text-align: left;
    vertical-align: middle;

    img {
      width: 100%;
      max-width: 300px;
      vertical-align: middle;
    }

    &__left {
      width: 50%;
      display: inline-block;
      padding-right: 20px;
      box-sizing: border-box;
      
    }

    &__right {
      width: 50%;
      display: inline-block;
      padding-left: 20px;
      box-sizing: border-box;
    }

    
  }

  // .footer-poweredby {
  //   font-size: 10px;
  //   font-weight: bold;
  //   font-family: 'Avenir', sans-serif!important;
  // }

  // .poweredbySE {
  //   background-color: #161918;
  //   width: 80%;
  //   max-width: 250px;
  //   border-radius: 40px;
  //   margin: 20px auto 0px auto;
  //   display: block;
  //   color: white;
  //   text-decoration: none;
  //   font-family: 'Avenir', sans-serif!important;

  //   img {
  //     display: inline-block;
  //     vertical-align: middle;
  //     min-width: 120px;
  //     width: 40%;
  //     padding: 10px;
  //   }
  // }
}
