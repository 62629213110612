@import 'colours';

%button-base {
  appearance: none;
  border: none;
  border-radius: 40px;
  font-family: 'MarkWeb', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0.83px;
  text-align: center;
  border-style: inherit;
  padding: 8px 50px 12px 50px;
  outline: none;
  transition: transform 0.500s ease-out;
}

.button-primary {
  @extend %button-base;
  color: $dark-blue;
  background-color: $green;
}

.button-secondary {
  @extend %button-base;
  background-color: $purple;
}

.button--hidden {
  opacity: 0;
  transform: translateY(100%);
}

.button-explore {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: 'MarkWeb', arial, sans-serif;
  font-weight: bold;
  display: block;
  margin-top: 0px;
  font-size: 16px;
  color: white;

  .arrow {
    background-color: $purple;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 10px;
    margin: 10px auto;

    img {
      display: block;
      width: 25px;
      margin: 5px auto 0px auto;
    }
  }
}
