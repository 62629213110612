@import 'typography';
@import 'colours';
@import 'buttons';
@import 'easings';

.IntroView {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .bg-mask {
    background-image: linear-gradient(90deg,rgba(26,35,67,.5),rgba(26,35,67,.5));
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .bg {
    background: url('../assets/backgroundImg.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;

    // .content-details {
    //   // padding: 45vh 0 0 0 ;
    //   font-family: 'Interstate', arial, sans-serif;
    //   color: #ffffff;

    //   .carousel {
    //     margin-top: 45%;
    //   }
    // }
  }

  &__carousel {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 600px;
    width: 100vw;
    height: 400px;
    margin: -150px 0 0 -50vw;
    will-change: transform, opacity;
    transition: transform 0.500s $ease-out-quart, opacity 0.300s ease-out;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;

    &--hidden {
      opacity: 0;
      transform: translateX(100vw);
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    will-change: transform;
    transition: transform 0.500s $ease-out-quart;

    &--second {
      transform: translateX(-100vw);
    }
  }

  &__slide {
    position: absolute;
    top: 0;
    display: block;
    max-width: 600px;
    width: 100vw;
    height: 100%;
    text-align: left;
    font-family: 'Interstate', arial, sans-serif;
    color: white;
    box-sizing: border-box;
    margin: 0;
    padding: 0 30px 0 60px;

    h2, h3, p {
      padding-right: 24px;
    }

    .button {
      margin: 20px auto 0 auto;
    }

    .button-explore {
      margin-top: 40px;
    }

    &:nth-child(2) {
      left: 100vw
    }
  }
}

// .introCarousel {
//   height: 275px;
//   will-change: transform;
//   transition: transform 0.500s $ease-out-quart;
//   transform: translateX(100%);
//   margin-bottom: 20px;

//   &--entry {
//     transform: translateX(0);
//   }
// }

// .anotherSlide {
//   text-align: left;
//   margin: 0px 60px 0px 30px;
//   width: auto!important;
// }

